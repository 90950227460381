jQuery(document).ready(function($) {

  /**
    * Sometimes webkit browser have repaint issues with hovers (specifically Safari in this instance). So force it :|
    * http://stackoverflow.com/questions/31587890/safari-on-mac-not-updating-redrawing-after-hover-effect
    **/
    $('.menu-item-has-children').on('mouseleave', function() {
      //Check user agent to see if this is safari. If so, be gross and force a repaint.
      var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if(is_safari) {
       $('body').hide().show(0);
     }
    });

  //Our mobile menu
  var sidrName = 'sidr-main';

    $('#sidr-toggle').sidr({
        name: sidrName,
        side: 'right',
        source: '.site-header__secondary-form, .main-navigation, .site-header__secondary-nav-container',
        renaming: false,
        onOpen: function(){
            $( '#page' ).on('click.sidr', function (e) {
            $.sidr('close', sidrName);
          });
        },
        onClose: function(){
            $( '#page' ).off('click.sidr');
        }
    });

  $('#' + sidrName).on('click.sidr', function (e) {
    e.stopPropagation();
  });

  //Add toggle elements for Sidr submenus
  $('.sidr-inner .menu-item-has-children > a').after('<div class="sidr-sub-toggle"><span class="icon-plus"></span></div>');

  //Toggle functions for Sidr submenus
  $('.sidr-sub-toggle').on('click', function() {
    if ( $(this).next('.sub-menu-wrap').hasClass('sidr-sub-open') ) {
      $(this).find('span').removeClass('icon-minus').addClass('icon-plus');
      $(this).next('.sub-menu-wrap').removeClass('sidr-sub-open');
    } else {
      $(this).find('span').addClass('icon-minus').removeClass('icon-plus');
      $(this).next('.sub-menu-wrap').addClass('sidr-sub-open');
    }
  });

});
